// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
$valuable-typography: mat.define-legacy-typography-config(
        $font-family: 'Montserrat, sans-serif'
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$valuable-palette: (
        50: #ede7f6,
        100: #d1c4e9,
        200: #b39ddb,
        300: #9575cd,
        400: #7e57c2,
        500: #673ab7,
        600: #5e35b1,
        700: #512da8,
        800: #4527a0,
        900: #311b92,
        A100: #b388ff,
        A200: #7c4dff,
        A400: #651fff,
        A700: #6200ea,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: white,
                600: white,
                700: white,
                800: white,
                900: white,
                A100: rgba(black, 0.87),
                A200: white,
                A400: white,
                A700: white,
        )
);

$valuable-app-primary: mat.define-palette($valuable-palette);
$valuable-app-accent: mat.define-palette($valuable-palette, A200, A100, A400);


// The warn palette is optional (defaults to red).
$valuable-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$valuable-app-theme: mat.define-light-theme((
        color: (
                primary: $valuable-app-primary,
                accent: $valuable-app-accent,
                warn: $valuable-app-warn,
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-typographies($valuable-typography);
@include mat.all-legacy-component-themes($valuable-app-theme);
