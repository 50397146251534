/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Styling Material Dialogs is a pain and you have to enter them all in styles.css
// They are loaded globally with the framework, so adding styling dialogs inside components does not work.
// Dialog styles are computed and applied when the framework loads, not when the component loads.
// No styles here means no styles applied
.valuable-dialog .mat-dialog-container {
  border-radius: 20px;
  height: 31rem;
  width: 36rem;
}

.project-dialog .mat-dialog-container {
  border-radius: 20px;
  height: 50rem;
  width: 36rem;
}

.idea-item-action-dialog .mat-dialog-container {
  border-radius: 20px;
  height: 26rem;
  width: 36rem;
}

.customer-story-dialog .mat-dialog-container {
  border-radius: 20px;
  height: 40rem;
  width: 120rem;
}


// Super yuck way of globally styling sliders, only thing I've found that works though - https://stackoverflow.com/questions/52339591/style-the-angular-material-slider-so-that-it-is-thicker-taller
// #side-nav-container > div:nth-child(2) > div > app-valuation-market > div > form > ol > li:nth-child(3) > div:nth-child(2) > mat-slider > div > div.mat-slider-thumb-container > div.mat-slider-thumb

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: #c9abf7;
}

.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 2rem
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

#side-nav-container > div > div > * > div > form > ol > li > div > mat-slider > div > div.mat-slider-thumb-container > div.mat-slider-thumb {
  bottom: -15px;
  right: -15px;
  transform: scale(1.5);
  background-color: #7342bf;
  border: solid 2px #7342bf;
}

@font-face {
  font-family: GTWalsheimProMedium;
  src: url("assets/font/GTWalsheimPro-Medium.ttf");
}

//Custom theming
.spacer {
  flex: 1 1 auto;
}


.primary-toolbar-text {
  color: #3C3250FF;
  font-weight: 600;
}

.modal-submit-button {
  border-radius: 1.5rem;
  width: 100%;
  height: 50px;
  font-size: 24px;
  font-weight: 600;
}

.cancel-button {
  font-size: 35px;
  color: #3C3250FF;
}

.item-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
  color: #3C3250FF;
  font-weight: 600;
}

.full-width {
  width: 100%;
}

.item-dialog-content {
  margin-top: 20px !important;
  font-weight: 600;
  padding-left: 40px !important;
  padding-right: 40px !important;
  overflow: unset !important;
}

// Fucking angular team deprecated flexLayout (~350k downloads on npm per WEEK - but it was in 'beta')
// The below block is a bodge of the flex styles used so that I can migrate away with a minimum of effort.
// This is how technical debt happens.

// Flex row/col + grid.
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
}

.grid {
  display: grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col-xs {
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.flex-col-sm {
  @media screen and (min-width: 959px) {
    flex-direction: column;
  }
}

.flex-row-xs {
  @media screen and (max-width: 599px) {
    flex-direction: row;
  }
}

.flex-row-sm {
  @media screen and (min-width: 959px) {
    flex-direction: row;
  }
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-14 {
  gap: 14px;
}

.gap-20 {
  gap: 20px;
}

.flex-auto {
  flex: 1 1 auto;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.stretch {
  justify-content: stretch;
}

.align-stretch {
  align-content: stretch
}

.start {
  justify-content: flex-start;
}

.content-start {
  align-content: flex-start;
}

.flex-start {
  align-items: flex-start;
}

.end {
  justify-content: end;
}

.flex-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.flex {
  display: flex !important;
}

.flex-1 {
  flex: 1
}

.flex-grow {
  flex-grow: 1;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
